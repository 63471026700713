.tweak-footer-show .Footer {
  .sns__icons {
    .icons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .icon {
      a {
        display: block;
      }
    }

    .icon__twitter {
      margin-top: pxToRem(5);
    }

    .icon__twitter,
    .icon__insta {
      padding: pxToRem(14);
    }

    .icon {
      width: pxToRem(64);
      height: pxToRem(64);
    }
  }
}