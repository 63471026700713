.Index {
  #image {
    .Index-page-content {
      padding: pxToRem(60) pxToRem(40);

      @include mq() {
        padding: pxToRem(30) pxToRem(40);
      }

      .sqs-block-content {
        p {
          img {
            width: 40%;

            @include mq() {
              width: 60%;
            }
          }
        }
      }
    }
  }

  #writing {
    .Index-page-content {
      padding-top: pxToRem(60);

      @include mq() {
        padding-top: pxToRem(30);
      }
    }
  }

  #links {
    .Index-page-content  {
      .sqs-layout {
        .row {
          .row {
            .sqs-block-code {
              .sqs-block-content {
                iframe {
                  margin: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}