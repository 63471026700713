* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

a.summary-read-more-link,
a.BlogList-item-readmore {
  display: inline !important;
  padding-bottom: 0.3125rem;
  font-weight: bold;
  color: #1d4f68;
  border-bottom: 0.0625rem solid #1d4f68;
}

.Index #image .Index-page-content {
  padding: 3.75rem 2.5rem;
}

@media screen and (max-width: 735px) {
  .Index #image .Index-page-content {
    padding: 1.875rem 2.5rem;
  }
}

.Index #image .Index-page-content .sqs-block-content p img {
  width: 40%;
}

@media screen and (max-width: 735px) {
  .Index #image .Index-page-content .sqs-block-content p img {
    width: 60%;
  }
}

.Index #writing .Index-page-content {
  padding-top: 3.75rem;
}

@media screen and (max-width: 735px) {
  .Index #writing .Index-page-content {
    padding-top: 1.875rem;
  }
}

.Index #links .Index-page-content .sqs-layout .row .row .sqs-block-code .sqs-block-content iframe {
  margin: 0 !important;
}

body[id^="collection-"] .Intro div[data-layout-label="Intro"] {
  display: none;
}

body[id^="collection-"] .Main .Main-content {
  padding-top: 3.75rem !important;
}

body[id^="collection-"] .BlogList-filter {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: bold;
}

body[id^="collection-"] .Site.show-intro div[data-layout-label="Intro"] {
  display: block;
}

body[id^="collection-"] .Site.show-intro .Main .Main-content {
  padding-top: 0 !important;
}

body[id^="collection-"] .BlogList-pagination-link-label,
body[id^="collection-"] .BlogList-pagination-link-icon {
  padding: 0.0625rem;
}

body[id^="item-"] .Site .Main-content .Share-buttons {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding-top: 0.625rem;
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  background-color: #ffffff;
  z-index: 99;
  transform: translateY(100%);
  transition: 0.5s;
}

body[id^="item-"] .Site .Main-content .Share-buttons .Share-buttons-item {
  fill: #bd9e5c;
}

body[id^="item-"] .Site .Main-content .Share-buttons .Share-buttons-item {
  margin: 0.9375rem;
}

body[id^="item-"] .Site .Main-content .Share-buttons .Share-buttons-item .Share-buttons-item-icon {
  transform: scale(3) !important;
}

body[id^="item-"] .Site .Main-content .Share-buttons .Share-buttons-item.Share-buttons-item--like {
  display: none;
}

body[id^="item-"] .Site .Main-content .Share-buttons.show {
  transform: translateY(0);
}

body[id^="item-"] .Footer .Footer-inner {
  padding-bottom: 5.3125rem;
}

@media screen and (max-width: 735px) {
  body[id^="item-"] .Footer .Footer-inner {
    padding-bottom: 5.3125rem;
  }
}

.tweak-footer-show .Footer .sns__icons .icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tweak-footer-show .Footer .sns__icons .icon a {
  display: block;
}

.tweak-footer-show .Footer .sns__icons .icon__twitter {
  margin-top: 0.3125rem;
}

.tweak-footer-show .Footer .sns__icons .icon__twitter,
.tweak-footer-show .Footer .sns__icons .icon__insta {
  padding: 0.875rem;
}

.tweak-footer-show .Footer .sns__icons .icon {
  width: 4rem;
  height: 4rem;
}
