body[id^="item-"] {
  .Site {
    .Main-content {
      .Share-buttons {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding-top: pxToRem(10);
        padding-bottom: calc(10px + env(safe-area-inset-bottom));
        background-color: #ffffff;
        z-index: 99;
        transform: translateY(100%);
        transition: 0.5s;

        .Share-buttons-item {
          fill: #bd9e5c;
        }

        .Share-buttons-item {
          margin: pxToRem(15);

          .Share-buttons-item-icon {
            transform: scale(3) !important;
          }
        }

        .Share-buttons-item.Share-buttons-item--like {
          display: none;
        }
      }

      .Share-buttons.show {
        transform: translateY(0);
      }
    }
  }

  .Footer {
    .Footer-inner {
      padding-bottom: pxToRem(85);
      @include mq() {
        padding-bottom: pxToRem(85);
      }
    }
  }
}