* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

a.summary-read-more-link,
a.BlogList-item-readmore {
  display: inline !important;
  padding-bottom: pxToRem(5);
  font-weight: bold;
  color: #1d4f68;
  border-bottom: pxToRem(1) solid #1d4f68;
}