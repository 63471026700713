body[id^="collection-"] {
  .Intro {
    div[data-layout-label="Intro"] {
      display: none;
    }
  }

  .Main {
    .Main-content {
      padding-top: pxToRem(60) !important;
    }
  }

  .BlogList-filter {
    @include fontSizePxToRem(20);
    font-weight: bold;
  }
}

body[id^="collection-"] {
  .Site.show-intro {
    div[data-layout-label="Intro"] {
      display: block;
    }

    .Main {
      .Main-content {
        padding-top: 0 !important;
      }
    }
  }

  .BlogList-pagination-link-label,
  .BlogList-pagination-link-icon {
    padding: pxToRem(1);
  }
}


